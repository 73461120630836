import React from "react"
import SEO from "components/Seo"
import classNames from "classnames/bind"
import PageHeader from "components/PageHeader"
import styles from "./gallery.module.css"
import Lightbox from "components/Lightbox"
import { AnimatePresence } from "framer-motion"
import Figure from "components/Figure"

import { client } from "lib/helpers"

const cx = classNames.bind(styles)

const GalleryPage = ({ data }) => {
  const images = data?.sanityGallery?.images.map(image => ({
    ...image,
    asset: {
      ...image.asset,
      _ref: image.asset._id,
    },
  }))
  const [lightbox, setLightbox] = React.useState(null)
  const [imgs, setImgs] = React.useState(images)

  const query = '*[_type=="gallery"][0]{images}'

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await client.fetch(query)

        if (res && res.images.length) {
          setImgs(res.images)
        }
      } catch (error) {
        console.error("err: ", error.message)
      }
    }

    fetchData()
  }, [])

  const handleOnClick = index => {
    setLightbox(index)
  }

  const handleClose = () => {
    setLightbox(null)
  }

  return (
    <>
      <SEO title={data.sanityGallery?.title} />
      <div className={cx("wrapper")}>
        <div className={cx("header-wrap")}>
          <PageHeader className={cx("header")} title="Galerija">
            {data.sanityGallery?.title}
          </PageHeader>
        </div>
        <div className={cx("gallery-wrap")}>
          <div className={cx("gallery")}>
            {imgs.map((image, index) => {
              return (
                <div
                  key={`gi-${index}`}
                  className={cx("gallery-item")}
                  onClick={() => handleOnClick(index)}
                >
                  <Figure className={cx("gallery-itemImg")} node={image} />
                </div>
              )
            })}

            {/* {images.map((image, index) => (
              <div
                role="img"
                key={image._key}
                className={cx("gallery-item")}
                onClick={() => handleOnClick(index)}
              >
                <Img
                  fluid={{ ...image.asset.fluid, aspectRatio: 1 / 1 }}
                  imgStyle={{ objectFit: "cover", objectPosition: "50% 50%" }}
                  className={cx("gallery-itemImg")}
                />
              </div>
            ))} */}
          </div>
        </div>
      </div>
      <AnimatePresence>
        {lightbox !== null && (
          <Lightbox
            images={imgs}
            initialImage={lightbox}
            closeLightbox={handleClose}
          />
        )}
      </AnimatePresence>
    </>
  )
}

export const query = graphql`
  {
    sanityGallery {
      id
      title
      images {
        _key
        asset {
          _id
          fluid(maxWidth: 1600) {
            ...GatsbySanityImageFluid
          }
          fixed(width: 1600) {
            ...GatsbySanityImageFixed
          }
        }
      }
    }
  }
`

export default GalleryPage
